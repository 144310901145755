import artsy from '../../assets/artsy.webp'
import finance from '../../assets/financefast.webp'
import car from '../../assets/car.webp'
import marvel from '../../assets/marvel.webp'
import moviebox from '../../assets/moviebox.webp'
import helpmeout from '../../assets/helpmeout.webp'
import getcrib from '../../assets/getcrib.png'

export const projectarr = [
  {
    id: 0,
    title: "GetCrib",
    live: "https://getcrib.co",
    description: "Apartment rental platform",
    stack: "NEXTJS + TYPESCRIPT + REDUX",
    snippet: getcrib
  },
  {
    id: 1,
    title: "helpmeout",
    description: "Screen recording chrome extension plugin",
    stack: "JAVASCRIPT + CHROME SCREEN CAPTURE API",
    github: "https://github.com/shadrxcc/HelpMeOut-ext",
    snippet: helpmeout
  },

  {
    id: 2,
    title: "hd_cars_rent",
    description: " Fun car reservation experience",
    stack: "REACTJS + REDUX + RUBY ON RAILS",
    // live: "https://hd-cars-rent-git-dev-shadrxcc.vercel.app/",
    github: "https://github.com/shadrxcc/hd_cars_rent",
    status: '🚧 DEVELOPMENT',
    snippet: car
  },

  {
    id: 3,
    title: "artsy",
    description: "Discover 10,000+ untold stories in photos.",
    stack: "REACTJS + FRAMER MOTION + CONTEXT API",
    live: "https://artsy-fun.vercel.app/",
    github: "https://github.com/shadrxcc/artsy",
    snippet: artsy
  },

  {
    id: 4,
    title: "marvel-lp",
    description: "Marvel-comics inspired web experience",
    stack: "REACTJS + MARVEL API",
    live: "https://marvel-lp-ruby.vercel.app/",
    github: "https://github.com/shadrxcc/marvel-lp/",
    snippet: marvel
  },

  {
    id: 5,
    title: "finance-fast",
    description: "Simplify global transactions with FinanceFast",
    stack: "REACTJS",
    live: "https://finance-fast-two.vercel.app/",
    github: "https://github.com/shadrxcc/finance-fast",
    snippet: finance
  },

  {
    id: 6,
    title: "moviebox",
    description: "Discover your favourite movies",
    stack: "REACTJS + TMDB API",
    live: "https://moviebox-hngx.vercel.app/",
    github: "https://github.com/shadrxcc/moviebox",
    snippet: moviebox
  }
];
